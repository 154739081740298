<template>
  <q-page class="row items-center justify-evenly">
    <div class="col-12">
      <div>
        <q-img
            src="../assets/homeward-pharmacy.webp"
            alt="Homeward Pharmacy"
            fit="none"
            height="600px"
            position="right top"
            loading="eager"
            decoding="sync"
            no-spinner>
          
          <div class="main-text-wrapper absolute-center q-pa-lg q-pt-lg">
            <h1 class="text-homeward-purple main-text-header">
              A name you can <span class="text-gompels-green">trust.</span>
            </h1>
            
            <p class="text-homeward-purple main-text-description">
              Homeward Pharmacy has been serving patients at home since 1997.
            </p>
            
            <q-btn
                color="homeward-purple"
                size="lg"
                rounded
                class="q-my-lg q-px-lg"
                to="/about">
              Learn More
            </q-btn>
          </div>
        </q-img>
      </div>
      
      <q-carousel height="660px" animated infinite v-model="slide">
        <q-carousel-slide :name="1" class="column no-wrap flex-center" img-src="../assets/slide-flu-bg.webp">
          <div class="custom-caption q-pt-md text-white carousel-wrapper">
            <q-icon
                right
                name="fad fa-file-waveform float-right q-mt-md"
                size="xl"
                style="font-size: 30vmin"
            />
            
            <h1 class="carousel-text-header">
              Learn more about your dispensing service with <span class="text-homeward-purple">Homeward Pharmacy</span>
            </h1>
            
            <p class="carousel-text-description">
              Make sense of your prescription journey now.
            </p>
            
            <div class="q-my-xl">
              <q-btn
                  color="homeward-purple"
                  rounded outline
                  size="md"
                  class="q-mb-md q-mr-md"
                  to="/dispensing-service/homeward-pharmaceutical-service">
                Homeward Pharmaceutical Service
              </q-btn>
              
              <q-btn
                  color="homeward-purple"
                  rounded outline
                  size="md"
                  class="q-mb-md q-mr-md"
                  to="/dispensing-service/nutricia-homeward-service">
                Nutricia Homeward Service
              </q-btn>
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
  </q-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PageIndex',
  setup() {
    const slide = ref(1);
    
    return {
      slide,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-text-wrapper {
  height: 425px;
  background: none;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.main-text-header {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-shadow: 2px 2px 10px #fff, -2px -2px 10px #fff;
}

.main-text-description {
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 10px #fff, -2px -2px 10px #fff;
}

.carousel-wrapper {
  max-width: 1104px;
}

.carousel-text-header {
  font-size: 2.5rem;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.carousel-text-description {
  font-size: 1.3em;
  max-width: 900px;
  font-weight: 300 !important;
  margin-top: 0;
  margin-bottom: 1rem;
}
</style>